import React, { useRef } from 'react';
import base from '../api/base';
import { useNavigate, Link } from 'react-router-dom';

function AddOrder() {
  const navigate = useNavigate();
  const addressRef = useRef();
  const foodRef = useRef();
  const peopleRef = useRef();
  const notesRef = useRef();

// write a function to handle the submit and console.log the values
  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate to the thank you page
    navigate('/thank-you');
  };

  const addOrder = (e) => {
    e.preventDefault();
    const Address = addressRef.current.value;
    const Food = foodRef.current.value;
    const People = peopleRef.current.value;
    const Notes = notesRef.current.value;

    base('orders').create(
      { Address, Food, People, Notes },
      function (err, record) {
        if (err) {
          console.error(err);
          return;
        }
        console.log("Order has been added");
      }
    );
    // clear the form
    addressRef.current.value = '';
    foodRef.current.value = '';
    peopleRef.current.value = '';
    notesRef.current.value = '';

    // navigate to the thank you page
    navigate('/thank-you');
    

  };

  return (
    <div className="groupContainer">
      <form>
        <div className="form-group pad-bottom-small">
          <label htmlFor="name">Address to send food to</label>
          <input 
            placeholder="Add address to receive food" 
            ref={addressRef}
           />
        </div>
        <div className="form-group">
          <label htmlFor="name">What are you craving?</label>
          <select 
            placeholder="nom nom nom... "
            ref={foodRef}>
            <option  value="" disabled selected>nom, nom, nom</option>
            <option value="You Choose">Randomize - jubee chooses</option>
            <option value="Vietnamese">Vietnamese</option>
            <option value="Thai">Thai</option>
            <option value="Sushi">Sushi</option>
            <option value="Healthy">Healthy</option>
            <option value="Burgers">Burgers</option>
            <option value="Mexican">Mexican</option>
            <option value="Chinese">Chinese</option>
            <option value="Italian">Italian</option>
            <option value="Pasta">Pasta</option>
            <option value="Filipino">Filipino</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">How many people should the order feed?</label>
          <select 
            ref={peopleRef}>
            <option  value="" disabled selected>Just me or 2 people</option>
            <option value="solo">Just Me</option>
            <option value="Double">2 People</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Any Other Notes?</label>
          <textarea 
            placeholder="Anything to add?"
            ref={notesRef}></textarea>
          <Link to="/thank-you">              
            <button type="submit" onClick={addOrder} className="mt-4 btn btn-primary d-block"> Feed Me </button>
          </Link>
        </div>
        <div className="push-bottom-xxlarge"></div>
      </form>
    </div>
  )
};

export default AddOrder;