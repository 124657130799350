import React from "react";
import WebFont from 'webfontloader';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './components/Home';
import OrderContainer from './components/OrderContainer';
import ThankYou from "./components/ThankYou";


WebFont.load({
  google: {
    families: ['Poppins:500,600,700,800', 'Press Start 2P:400', 'sans-serif']
  }
});

const App = (props) => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/feed-me" element={<OrderContainer />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  </Router>
)

export default App;
