import TypeWriterEffect from 'react-typewriter-effect';
import AddOrder from './AddOrder';
import logo from '../imgs/logo.svg';
import jubee from '../imgs/jubee.svg';

const MessageComponent = () => {
  const messageRef = document.querySelector('.messageContainer')

  return (
    <div className='messageContainer'>
      <img src={jubee} alt="jubee" className='jubee' />
      <div className="inner">
        <div className="message">
          <TypeWriterEffect
            textStyle={{ fontFamily: 'Press Start 2P' }}
            startDelay={100}
            cursorColor="black"
            text="Ok, you’re hungry. Let’s get that handled."
            typeSpeed={100}
            scrollArea={messageRef}
          />
        </div>
      </div>
    </div>
  );
};

function OrderContainer() {
  return (
    <div className="container-fluid appContainer">
      <div className="stars"></div>
      <div className="container mobile-Container">
        <img src={logo} alt="" className='push-top-smedium logo' />
        <div className="jubeeBox pad-top-small pad-bottom-xxlarge">
          <div className="row">
            <div className="col-md-3 col-sm-0"></div>
            <div className="col-md-6 col-sm-12 center">
              <MessageComponent />
              <AddOrder />
            </div>
            <div className="col-md-3 col-sm-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderContainer;