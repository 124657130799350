import React, {useState,useEffect} from 'react';
import TypeWriterEffect from 'react-typewriter-effect';
import { Link } from 'react-router-dom';
import AddOrder from './AddOrder';
import logo from '../imgs/logo.svg';
import jubee from '../imgs/jubee.svg';

const MessageComponent = () => {
  const messageRef = document.querySelector('.messageContainer')

  return (
    <div className='messageContainer'>
      <img src={jubee} alt="jubee" className='jubee' />
      <div className="inner">
        <div className="message">
          <TypeWriterEffect
            textStyle={{ fontFamily: 'Press Start 2P' }}
            startDelay={100}
            cursorColor="black"
            text="Hi, Christina! Looking quite T-Rrrific. How can I help you?"
            typeSpeed={100}
            scrollArea={messageRef}
          />
        </div>
      </div>
    </div>
  );
};

const ButtonGroupComponent = () => {
  return (
    <div className="groupContainer">
      <Link to="/feed-me"><button className='btn'>I'm hungry</button></Link>
      <p>Coming Up Soon</p>
      <button className='btn grey-btn'>Peace N Vibes</button>
      <button className='btn grey-btn'>Encouragement Wall</button>

      <div className="push-top-small">
        <a className="link" target="_blank" href="https://app.asana.com/0/1203246468565779/board">Add Ideas to Future Builds Here</a>
      </div>
    </div>
  )
};

export default function Home() {
  return (
    <div className="container-fluid appContainer">
      <div className="stars"></div>
      <div className="container">
        <img src={logo} alt="" className='push-top-smedium logo' />
        <div className="jubeeBox pad-top-small pad-bottom-xxlarge">
          <div className="row">
            <div className="col-md-3 col-sm-0"></div>
            <div className="col-md-6 col-sm-12 center">
              <MessageComponent />
              <ButtonGroupComponent />
              {/* <AddOrder /> */}
            </div>
            <div className="col-md-3 col-sm-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
}