import TypeWriterEffect from 'react-typewriter-effect';
import logo from '../imgs/logo.svg';
import jubee from '../imgs/jubee.svg';
import { Link } from 'react-router-dom';

const MessageComponent = () => {
  const messageRef = document.querySelector('.messageThankContainer')

  return (
    <div className='messageThankContainer'>
      <img src={jubee} alt="jubee" className='jubee' />
      <div className="inner">
        <div className="message">
          <TypeWriterEffect
            textStyle={{ fontFamily: 'Press Start 2P' }}
            startDelay={100}
            cursorColor="black"
            text="Great. Your food will be on its way soon. I'll text you an update."
            typeSpeed={100}
            scrollArea={messageRef}
          />
        </div>
      </div>
    </div>
  );
};

function ThankYou() {
  return (
    <div className="container-fluid appContainer">
      <div className="stars"></div>
      <div className="container mobile-Container">
        <img src={logo} alt="" className='push-top-smedium logo' />
        <div className="jubeeBox pad-top-small pad-bottom-xxlarge">
          <div className="row">
            <div className="col-md-3 col-sm-0"></div>
            <div className="col-md-6 col-sm-12 center">
              <MessageComponent />
              <div className="groupContainer">
                <Link to="/">              
                  <button className='btn'>Go Home</button>
                </Link>
              </div>

            </div>
            <div className="col-md-3 col-sm-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;